/**
 * External Dependencies
 */

import 'jquery';

import { createPopper } from '@popperjs/core';
import { Util, Collapse, Tooltip, OffCanvas } from 'bootstrap';

import Swiper, { Navigation, Lazy, Autoplay } from 'swiper';

//import { library, dom } from '@fortawesome/fontawesome-svg-core';

import SimpleLightbox from 'simplelightbox';

//import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars';
//import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
//import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight';

//import { faHome } from '@fortawesome/pro-light-svg-icons/faHome';
//import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers';
//import { faCogs } from '@fortawesome/pro-light-svg-icons/faCogs';
//import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone';
//import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
//import { faTypewriter } from '@fortawesome/pro-light-svg-icons/faTypewriter';
//import { faBriefcase } from '@fortawesome/pro-light-svg-icons/faBriefcase';
//import { faPencilAlt } from '@fortawesome/pro-light-svg-icons/faPencilAlt';
//import { faLaptopCode } from '@fortawesome/pro-light-svg-icons/faLaptopCode';
//import { faVials } from '@fortawesome/pro-light-svg-icons/faVials';
//import { faRocket } from '@fortawesome/pro-light-svg-icons/faRocket';
//import { faUserHeadset } from '@fortawesome/pro-light-svg-icons/faUserHeadset';

//import { faBrowser } from '@fortawesome/pro-regular-svg-icons/faBrowser';
//import { faShoppingCart } from '@fortawesome/pro-regular-svg-icons/faShoppingCart';
//import { faDesktopAlt } from '@fortawesome/pro-regular-svg-icons/faDesktopAlt';

$(document).ready(() => {

	var supportsTouch = 'ontouchstart' in window ||
	 window.DocumentTouch && document instanceof window.DocumentTouch ||
	 navigator.maxTouchPoints > 0 ||
	 window.navigator.msMaxTouchPoints > 0;
	 
	if(supportsTouch){
		$('.menu-item-has-children > a').on('click', function( event ){
			event.preventDefault();
			$(this).parents('ul').find('.menu-item.open').removeClass('open');
			$(this).parents('.menu-item').addClass('open');
		});
	}

	function castParallax() {

		var opThresh = 350;
		var opFactor = 750;

		window.addEventListener("scroll", function(event){
			//requestAnimationFrame()
			var top = this.pageYOffset;

			if(top < 800){
				var layers = document.getElementsByClassName("parallax");
				var layer, speed, yPos;
				for (var i = 0; i < layers.length; i++) {
					layer = layers[i];
					speed = layer.getAttribute('data-speed');
					var yPos = (top * speed / 200);
					layer.setAttribute('style', 'background-position-y:' + yPos + 'px');

				}
			}
		}, {passive:true});

	}


	if(top > 100){
		$('#header').addClass('bg-dark');
	} else {
		$('#header').removeClass('bg-dark');
	}
	
	window.addEventListener("scroll", function(event){
		var top = this.pageYOffset;

		if(top > 100){
			$('#header').addClass('bg-dark');
		} else {
			$('#header').removeClass('bg-dark');
		}

	}, {passive:true});

	if($('body').hasClass('home'))
		document.body.onload = castParallax();
	

	$('a[href*="#"]', '#content')
		.not('[href="#"]')
		.not('[href="#0"]')
		.click(function(event) {
		var target = $(this.hash);
		target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
		if (target.length) {
			event.preventDefault();
			$('html, body').animate({
				scrollTop: target.offset().top,
			}, 1000, function() {
				var $target = $(target);
				$target.focus();
				if ($target.is(':focus')) { 
					return false;
				} else {
					$target.attr('tabindex','-1'); 
					$target.focus();
				}

			});
		}
	});

	var lazyEmbeds = [].slice.call(document.querySelectorAll('video.lazy, iframe.lazy'));

	if ('IntersectionObserver' in window) {
		var lazyEmbedObserver = new IntersectionObserver(function(entries, observer) {
			entries.forEach(function(embed) {
				if (embed.isIntersecting) {
					if (typeof embed.target.tagName === 'string' && embed.target.tagName === 'IFRAME') {
						embed.target.src = embed.target.dataset.src;
					} else {
						for (var source in embed.target.children) {
							var embedSource = embed.target.children[source];
							if (typeof embedSource.tagName === 'string' && embedSource.tagName === 'SOURCE') {
								embedSource.src = embedSource.dataset.src;
							}
						}

						embed.target.load();
					}

					embed.target.classList.remove('lazy');
					lazyEmbedObserver.unobserve(embed.target);
				}
			});
		});

		lazyEmbeds.forEach(function(lazyEmbed) {
			lazyEmbedObserver.observe(lazyEmbed);
		});
	}

	var lazyAnimations = [].slice.call(document.querySelectorAll('.animate-intro'));

	if ('IntersectionObserver' in window) {
		var lazyAnimationObserver = new IntersectionObserver(function(entries, observer) {
			entries.forEach(function(element) {
				if (element.isIntersecting) {
					element.target.classList.add('animate');
					element.target.classList.remove('animate-intro');
					lazyAnimationObserver.unobserve(element.target);
				} 
			});
		}, {
			root: null,
			rootMargin: '0px',
			threshold: [0,1],
		});

		lazyAnimations.forEach(function(lazyAnimation) {
			lazyAnimationObserver.observe(lazyAnimation);
		});
	}

	if($('.wp-block-gallery a', '#main').length){
		const gallery = new SimpleLightbox('#main .wp-block-gallery a', {
			navText: [
				'<button rel="button" class="btn btn-dark px-3 position-fixed top-50 start-0 translate-middle-y" aria-label="next" tabindex="-1"><i class="fal fa-angle-left" style="width: 1rem;"></i></button>',
				'<button rel="button" class="btn btn-dark px-3 position-fixed top-50 end-0 translate-middle-y" aria-label="previous" tabindex="-1"><i class="fal fa-angle-right" style="width: 1rem;"></i></button>',
				],
			closeText: '&times;',
		});
		gallery.on('shown.simplelightbox', function () {
			dom.i2svg();
		});
	}

	//Slider
	function initSwiper(carousel){
		var $carousel = $(carousel);

		var carouselOptions = {
			slidesPerView : $carousel.data('items') ? $carousel.data('items') 		: 1,
			spaceBetween: 0,
			cssMode: true,
			simulateTouch: true,
			navigation: {
				nextEl: $carousel.parent('.swiper').find('.swiper-button-next')[0],
				prevEl: $carousel.parent('.swiper').find('.swiper-button-prev')[0],
			},
			breakpoints: {
				414: {},
				576: {},
				768: {},
				992: {},
				1320: {},
			},
		};

		if( $carousel.data('xs-items') ){ 	carouselOptions.breakpoints[414].slidesPerView = parseInt($carousel.data('xs-items')); }
		if( $carousel.data('xs-center') ){ carouselOptions.breakpoints[414].centeredSlides = $carousel.data('xs-center'); }

		if( $carousel.data('sm-items') ){ 	carouselOptions.breakpoints[576].slidesPerView = parseInt($carousel.data('sm-items')); }
		if( $carousel.data('sm-center') ){ carouselOptions.breakpoints[576].centeredSlides = $carousel.data('sm-center'); }

		if( $carousel.data('md-items') ){ 	carouselOptions.breakpoints[768].slidesPerView = parseInt($carousel.data('md-items')); }
		if( $carousel.data('md-center') ){ carouselOptions.breakpoints[768].centeredSlides = $carousel.data('md-center'); }

		if( $carousel.data('lg-items') ){ 	carouselOptions.breakpoints[992].slidesPerView = parseInt($carousel.data('lg-items')); }
		if( $carousel.data('lg-center') ){ carouselOptions.breakpoints[992].centeredSlides = $carousel.data('lg-center'); }

		if( $carousel.data('xl-items') ){ 	carouselOptions.breakpoints[1320].slidesPerView = parseInt($carousel.data('xl-items')); }
		if( $carousel.data('xl-center') ){ carouselOptions.breakpoints[1320].centeredSlides = $carousel.data('xl-center'); }

		if( $carousel.data('lazy') ){ carouselOptions.preloadImages = true; carouselOptions.lazy = true;  }

		if( $carousel.data('direction') ){ carouselOptions.direction = $carousel.data('direction');}

		/*
		if( $carousel.data('thumbs') ){ 
			var $thumbCarousel = $( $carousel.data('thumbs') );
			if($thumbCarousel.length){
				var	thumbCarousel = $thumbCarousel[0];
				$thumbCarousel.addClass('swiper-container');
				carouselOptions.thumbs = { swiper: initSwiper(thumbCarousel) };
			}  
		}
		*/

		var swiper =  new Swiper(carousel, carouselOptions);

		if( $carousel.data('tabs') ){ 
			$carousel.siblings('.nav-tabs').find('.nav-link[data-target]').on('click', function(){
				swiper.slideTo($(this).data('target'));
			});

			swiper.on('slideChange', function () {
				$carousel.siblings('.nav-tabs').find('.nav-link').removeClass('active').attr('aria-selected', 'false');
				$carousel.siblings('.nav-tabs').find('[data-target="' + swiper.activeIndex + '"]').addClass('active').attr('aria-selected', 'true');
			});
		}
		
		return swiper;
	}


	function initSwipers(){
		Swiper.use([Navigation, Lazy]);
		$('.swiper-container', '#main').each(function(){
			var carousel = this, $carousel = $(this);
			if( $carousel.find('> .swiper-wrapper').children().length > 1 && (!$carousel.hasClass('no-mobile') || !isMobile)){
				const swiper = new initSwiper(this);
			} 
		});
	}

	if($('.swiper-container', '#main').length){
		if (document.readyState === 'complete') {
			initSwipers();
		} else {
			window.addEventListener('load', (event) => {
				initSwipers();
			}, {passive: true, once: true});
		}
	}


	if($('form.wpcf7-form', '#main, #footer').length){


		$('form.wpcf7-form', '#main, #footer').find('input, select, textarea').on('change', function(){

			$(this).removeClass('is-valid');
			$(this).removeClass('is-invalid');
			$(this).removeClass('animate');
			$(this).removeClass('animation-error');

			if(this.checkValidity() == false){
				$(this).addClass('is-invalid animate animation-error');


			} else if( $(this).attr('type') == 'checkbox' && $(this).prop('checked') === false ){

			} else {
				$(this).addClass('is-valid');
			}



		});

		$('form.wpcf7-form', '#main, #footer').on('submit', function(){
			var $form = $(this);
			$form.find('.is-invalid').removeClass('is-invalid');
			$form.addClass('sending');
		});


		document.addEventListener( 'wpcf7submit', function( event ) {

			var $form = $(event.target);

			$form.removeClass('sending');

			$('.screen-reader-response').addClass('sr-only');
			
			$('.wpcf7-not-valid', $form).addClass('is-invalid');
			$('.wpcf7-not-valid-tip', $form).addClass('invalid-feedback');

			$('.wpcf7-response-output', $form).attr('class', 'wpcf7-response-output').html('').append(event.detail.apiResponse.message);

			if(event.detail.apiResponse.status === 'mail_sent'){

				$('.wpcf7-response-output', $form).detach().insertAfter($form).addClass('alert alert-success').show();

				$form.slideUp( 'slow');

				if(typeof gtag == 'function'){
					gtag( 'event', 'generate_lead', {
						event_category: 'Contact',
						event_label: event.detail.contactFormId,
					});
				} else if(typeof ga == 'function'){
					ga( 'send', {
						hitType: 'event',
						eventCategory: 'Contact',
						eventAction: 'submit',
						eventLabel: event.detail.contactFormId,
					});
				} else {
					window.dataLayer.push({
						'event' : 'cf7submission',
						'formId' : event.detail.contactFormId,
						'response' : event.detail.inputs,
					});
				}

			} else if(event.detail.apiResponse.status === 'validation_failed'){

				$form.find('button[type="submit"]').addClass('animate animation-error');

				$form.find('.invalid-feedback').remove();
				$.each(event.detail.apiResponse.invalid_fields, function(k,v){
					$form.find('#'+v.idref).addClass('is-invalid').after('<div class="invalid-feedback">' + v.message + '</div>');
				});

				$('.wpcf7-response-output', $form).addClass('alert alert-danger');


			} else {
				$('.wpcf7-response-output', $form).addClass('alert alert-warning');
			}
		}, { passive: true }, false );
	}

});
